import React, { useContext } from 'react'
import { Canvas } from '@react-three/fiber'
import {
    isExpired,
    isPopped,
    UnmintedCubeWithId,
} from '../contract_interface/cube'
import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { useSelector } from 'react-redux'
import { cubesOfCubeState, selectCube } from '../redux/cubeSlice'
import Bounce from '../components/renders/bounce'
import Post from '../components/renders/post'
import Lights from '../components/renders/lights'
import Boxes from '../components/renders/boxes'
import Explode from '../components/renders/explode'
import { isMobile } from '../assets/styles/styleguide'
import { SelectedCubeContext } from '../globalContextProvider'

const MyCanvas = () => {
    const { selected, setSelected } = useContext(SelectedCubeContext)!
    const cubeData = useSelector(selectCube)
    const cubes = cubesOfCubeState(cubeData)
    const poppedCubes = cubes.filter(
        (cube) =>
            isPopped(cube.data) && !isExpired(cube.data, cubeData.lastBlock),
    )
    const unpoppedCubes = cubes.filter(
        (cube) =>
            !(isPopped(cube.data) && !isExpired(cube.data, cubeData.lastBlock)),
    )
    poppedCubes.push(UnmintedCubeWithId(0))
    return (
        <Canvas>
            <Explode>
                <Bounce>
                    <Boxes
                        selected={selected}
                        setSelected={setSelected}
                        cubeList={unpoppedCubes}
                        popped={false}
                        lastBlock={cubeData.lastBlock}
                    />
                    <Boxes
                        selected={selected}
                        setSelected={setSelected}
                        cubeList={poppedCubes}
                        popped={true}
                        lastBlock={cubeData.lastBlock}
                    />
                </Bounce>
            </Explode>
            <Lights />
            <Post />
            <OrbitControls
                makeDefault
                enableDamping={false}
                rotateSpeed={0.3}
                autoRotate
                autoRotateSpeed={0.25}
                maxDistance={100}
                maxPolarAngle={(Math.PI * 4) / 7}
            />
            <PerspectiveCamera
                makeDefault
                fov={65}
                position={isMobile ? [14, 8, 14] : [12, 6, 8]}
            />
        </Canvas>
    )
}

export default MyCanvas

import styled from 'styled-components'
import { colors, sizes, fonts } from '../../assets/styles/styleguide'
import { MetamaskFox } from '../logos/Metamask'

const StyledBox = styled.button`
    display: flex;
    align-items: center;
    height: 32px;
    gap: 8px;
    padding: 0px 12px 0px 10px;

    border: 1px solid ${colors.accentGold};
    box-sizing: border-box;
    border-radius: 12px;
    background: ${colors.background};

    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: ${sizes.small};
    color: ${colors.accentGold};
    text-align: center;
    line-height: 150%;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    .logo {
        fill: ${colors.accentGold};
    }

    :hover {
        background: ${colors.accentGold};
        color: ${colors.background};
        border-color: ${colors.background};

        .logo {
            fill: ${colors.background};
        }
    }
`

type ComponentProps = {
    onClick: () => void
}

const Component = ({ onClick }: ComponentProps) => {
    return (
        <StyledBox onClick={onClick}>
            <MetamaskFox size={18} className={'logo'} />
            Disconnect
        </StyledBox>
    )
}

export default Component

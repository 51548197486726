import styled from 'styled-components'
import xsgdPng from '../../assets/images/xsgd.png'

export const XsgdLogo = styled.div<{ size: number }>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background: url(${xsgdPng});
    background-repeat: no-repeat;
    background-size: 100%;
`

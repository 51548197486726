import styled from 'styled-components'
import ethPng from '../../assets/images/eth.png'

export const EthLogo = styled.div<{ size: number }>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background: url(${ethPng});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

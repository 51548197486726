import { getProvider } from './metamask'
import { updateBlock, updateCubes } from '../../redux/cubeSlice'
import { AppDispatch } from '../../redux/store'
import {
    getCubeData,
    mintEventSubscriber,
    mintMap,
    popEventSubscriber,
    popMap,
    transferEventSubscriber,
    transferMap,
} from '../../contract_interface/events'
import { toast } from 'react-toastify'
import { truncateAccount } from '../../util/helpers'
import { Event } from 'ethers'
import { CONFIG, LOGGER } from '../../consts'

export const useBlockNumber = () => {
    const provider = getProvider()
    return async () => provider.getBlockNumber()
}

export const useCubeFetch = (dispatch: AppDispatch, startBlock?: number) => {
    const provider = getProvider()
    const fn = async () => {
        const cubeData = await getCubeData(
            provider,
            startBlock || CONFIG.contract_block_number,
        )
        const bn = await provider.getBlockNumber()
        dispatch(updateCubes(cubeData))
        dispatch(updateBlock(bn))
    }
    return () =>
        toast.promise(fn, {
            pending: `Connecting to Ethereum`,
            error: `Failed to connect to the network`,
            success: `Connected!`,
        })
}

export const useSubscribe = (dispatch: AppDispatch, cubeId: number | null) => {
    const provider = getProvider()
    const mint = mintEventSubscriber(
        provider,
        cubeId,
        (_cubeId, _to, _baseColor, event: Event) => {
            const ev = mintMap(event)
            toast.info(
                `${truncateAccount(ev.to)} just minted Cube ${ev.cubeId}`,
            )
            dispatch(
                updateCubes({
                    mintEvents: [ev],
                    transferEvents: [],
                    popEvents: [],
                }),
            )
        },
    )
    const pop = popEventSubscriber(
        provider,
        cubeId,
        (_cubeId, _pop, event: Event) => {
            const ev = popMap(event)
            toast.info(`Cube ${ev.cubeId} was just popped`)
            dispatch(
                updateCubes({
                    mintEvents: [],
                    transferEvents: [],
                    popEvents: [ev],
                }),
            )
        },
    )
    const transfer = transferEventSubscriber(
        provider,
        cubeId,
        (_from, _to, _amount, event: Event) => {
            const ev = transferMap(event)
            dispatch(
                updateCubes({
                    mintEvents: [],
                    transferEvents: [ev],
                    popEvents: [],
                }),
            )
        },
    )
    return {
        subscribe: () => {
            LOGGER.log('Subscribing to events')
            mint.subscribe()
            pop.subscribe()
            transfer.subscribe()
        },
        unsubscribe: () => {
            LOGGER.log('Unsubscribing from events')
            mint.unsubscribe()
            pop.unsubscribe()
            transfer.unsubscribe()
        },
    }
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { colors, isMobile, mediaQuery } from '../assets/styles/styleguide'
import Branding from '../components/cards/Branding'
import Canvas from '../canvases/Single'
import CubeDetailedCard from '../components/cards/CubeDetailedCard'
import ButtonBar from '../components/buttons/ButtonBar'
import { sameBaseColor } from '../contract_interface/filters'
import { cubesOfCubeState, selectCube } from '../redux/cubeSlice'
import { isUnminted } from '../contract_interface/cube'

const Body = styled.div`
    position: static;
    width: 100%;
    min-height: 100%;
    background: ${colors.background};

    background: linear-gradient(246.48deg, #19163f 9.24%, #11021a 76.68%);

    display: flex;
    flex-direction: column;
    margin: 0;
`

const MainPage = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    background: none;
    margin: auto;
    overflow: hidden;

    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        position: absolute;
        align-items: center;
        width: auto;
        right: calc(50% - 320px);
        z-index: 1;
    }
`

const CanvasDiv = styled.div`
    min-height: 280px;
    padding-top: 40px;
    height: 20vh;
    width: 100%;
    background: none;
    margin: 0;

    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        position: static;
        padding-top: 0px;
        height: 100vh;
        width: 100%;
    }
`

const MainComponent = () => {
    const cubes = cubesOfCubeState(useSelector(selectCube))
    const { id } = useParams()
    const intId = id ? parseInt(id) : 0
    const cube = cubes[intId - 1]
    const numSimilar = cubes.filter(sameBaseColor(cube)).length
    const numMinted = cubes.filter((cube) => !isUnminted(cube.data)).length
    return (
        <>
            <Body>
                <CanvasDiv>
                    <Canvas cube={cube} />
                </CanvasDiv>
                <MainPage>
                    <CubeDetailedCard cube={cube} numSimilar={numSimilar} />
                </MainPage>
                {!isMobile && <Branding numMinted={numMinted} />}
                <ButtonBar mainPage={false} />
            </Body>
        </>
    )
}

export default MainComponent

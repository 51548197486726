import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useSelector } from 'react-redux'
import { cubesOfCubeState, selectCube } from '../../redux/cubeSlice'
import styled from 'styled-components'
import {
    colors,
    sizes,
    fonts,
    mediaQuery,
} from '../../assets/styles/styleguide'
import { Cube } from '../../contract_interface/types'
import { coordOfId, idOfCoord } from '../../util/helpers'
import { SelectedCubeContext } from '../../globalContextProvider'

const StyledDiv = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    position: absolute;
    left: 8px;
    bottom: 14px;

    text-align: center;

    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: ${sizes.regular};
    color: ${colors.primary};

    padding: 0;
    z-index: 99;

    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        margin: 0;
        right: 60px;
        bottom: 42px;
        align-items: flex-end;
    }
`

const Boxes = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: static;
    left: 24px;
    top: 56px;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

const StyledBox = styled.input`
    position: static;
    width: 64px;
    height: 36px;
    left: 0px;
    top: 0px;
    border-radius: 12px;
    flex: none;
    flex-grow: 0;
    margin: 0px 6px;
    color: ${colors.primary};
    padding: 0px 8px;

    border: 0;
    box-sizing: border-box;
    border-radius: 12px;
    background: ${colors.backgroundDarkGrey};

    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: ${sizes.regular};
    text-align: right;
    color: ${colors.primary};

    :focus {
        outline: none;
    }
`

const XBox = styled(StyledBox)`
    order: 0;
`
const YBox = styled(StyledBox)`
    order: 1;
`
const ZBox = styled(StyledBox)`
    order: 2;
`
const IdBox = styled(StyledBox)`
    order: 4;
`
const OrText = styled.div`
    margin: 0px 6px;
    order: 3;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`
const SearchText = styled.div`
    margin: 0px 10px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`

interface CoordString {
    x: string
    y: string
    z: string
    id: string
}

const Component = () => {
    const SelectedContext = useContext(SelectedCubeContext)
    const { selected, setSelected } = SelectedContext!
    const cubes = cubesOfCubeState(useSelector(selectCube))
    const [input, setInput] = useState<CoordString>({
        x: '',
        y: '',
        z: '',
        id: '',
    })

    const valueOfInput = (input: Cube | undefined) => {
        if (input) {
            const coord = coordOfId(input.data.id, 10)
            return {
                x: coord.x.toString(),
                y: coord.y.toString(),
                z: coord.z.toString(),
                id: input.data.id.toString(),
            }
        }
        return { x: '', y: '', z: '', id: '' }
    }

    useMemo(() => setInput(valueOfInput(selected)), [selected])

    const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newInput = parseInt(event.target.value)
        if (event.target.value && (!newInput || newInput > 9999)) {
            return
        }
        let newCoord = { ...input, id: event.target.value }
        if (parseInt(newCoord.id) <= 1000) {
            const coords = coordOfId(parseInt(newCoord.id), 10)
            newCoord = {
                ...newCoord,
                x: coords.x.toString(),
                y: coords.y.toString(),
                z: coords.z.toString(),
            }
        } else {
            newCoord = { ...newCoord, x: '', y: '', z: '' }
        }
        setInput(newCoord)
    }

    const handleCoordChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        axis: string,
    ) => {
        const newInput = parseInt(event.target.value)
        if (event.target.value && (!newInput || newInput > 99)) {
            return
        }
        let newCoord = { ...input, [axis]: event.target.value }
        if (
            newCoord.x &&
            parseInt(newCoord.x) <= 10 &&
            newCoord.y &&
            parseInt(newCoord.y) <= 10 &&
            newCoord.z &&
            parseInt(newCoord.z) <= 10
        ) {
            const id = idOfCoord(
                {
                    x: parseInt(newCoord.x),
                    y: parseInt(newCoord.y),
                    z: parseInt(newCoord.z),
                },
                10,
            )
            if (id <= 1000) {
                newCoord = { ...newCoord, id: id.toString() }
            }
        } else {
            newCoord = { ...newCoord, id: '' }
        }
        setInput(newCoord)
    }

    const handleCoordSubmit = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && input.id && parseInt(input.id) <= 1000) {
            setSelected(cubes[parseInt(input.id) - 1])
        }
    }

    const escFunction = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setSelected(undefined)
            }
        },
        [setSelected],
    )

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [escFunction])

    return (
        <StyledDiv>
            <SearchText>Search</SearchText>
            <Boxes>
                <XBox
                    value={input.x}
                    placeholder={`X`}
                    onChange={(e) => handleCoordChange(e, 'x')}
                    onKeyDown={handleCoordSubmit}
                />
                <YBox
                    value={input.y}
                    placeholder={`Y`}
                    onChange={(e) => handleCoordChange(e, 'y')}
                    onKeyDown={handleCoordSubmit}
                />
                <ZBox
                    value={input.z}
                    placeholder={`Z`}
                    onChange={(e) => handleCoordChange(e, 'z')}
                    onKeyDown={handleCoordSubmit}
                />
                <OrText>or</OrText>
                <IdBox
                    value={input.id}
                    placeholder={`ID`}
                    onChange={handleIdChange}
                    onKeyDown={handleCoordSubmit}
                />
            </Boxes>
        </StyledDiv>
    )
}

export default Component

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
    colors,
    sizes,
    fonts,
    mediaQuery,
} from '../../assets/styles/styleguide'
import {
    isOwner,
    isPopped,
    isUnminted,
    MintWithEth,
    MintWithXsgd,
    PopCube,
} from '../../contract_interface/cube'
import { Grid } from '@mui/material'
import { Cube } from '../../contract_interface/types'
import { coordOfId, truncateAccount } from '../../util/helpers'
import LoadButton from '../buttons/LoadingButton'
import TransactionButton from '../buttons/ToggleButton'
import CurrencyButton from '../buttons/CurrencyButton'
import Tooltip from '../tooltips/Tooltip'
import { toast } from 'react-toastify'
import { selectMetamask, updateApproval } from '../../redux/metamaskSlice'
import { ToggleButtonGroup } from '@mui/material'
import { XsgdLogo } from '../logos/Xsgd'
import { EthLogo } from '../logos/Eth'
import { ethers, Signer } from 'ethers'
import { increaseAllowance } from '../../contract_interface/xsgd'
import { AppDispatch } from '../../redux/store'
import detectEthereumProvider from '@metamask/detect-provider'
import { ExternalProvider } from '@ethersproject/providers'
import { selectCube } from '../../redux/cubeSlice'
import { CONFIG } from '../../consts'

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 6px;

    background: ${colors.backgroundGlass};
    border: 1px solid ${colors.backgroundDarkGrey};
    box-sizing: border-box;
    backdrop-filter: blur(1.5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    /* Mobile screens */
    width: 340px;
    min-height: 450px;
    padding: 18px;
    margin: 0 0 48px 0;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 340px;
        min-height: 450px;
        padding: 18px;
        margin: 0;
    }
`

const Rectangle = styled.div`
    width: 300px;
    height: 110px;
    background: rgba(57, 57, 57, 0.53);
    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-size: ${sizes.xxs};
    color: ${colors.secondary};
    text-align: center;
    line-height: 110px;
`

const ExporeLink = styled.a`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.secondary};
    margin: 0px;
    text-decoration-line: underline;

    /* Mobile screens */
    font-size: ${sizes.xs};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.xs};
        margin: 4px 0px;
    }
`

const Header = styled.div`
    font-family: ${fonts.fontFamilyHeader};
    font-style: normal;
    font-weight: bold;
    color: ${colors.primary};
    margin: 0;

    /* Mobile screens */
    font-size: ${sizes.xl};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.xl};
        margin: 0;
    }
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    width: 300px;
    left: 18px;
    top: 124px;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    margin: 0;
`

const BodyRow = styled.div`
    position: static;
    width: 100%;
    left: 0px;
    top: 0px;

    /* Inside auto layout */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const ButtonRow = styled.div`
    position: static;
    width: 100%;
    left: 0px;
    top: 0px;
    margin: 3px 0;

    /* Inside auto layout */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Title = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.secondary};
    margin: 4px 0px;

    /* Mobile screens */
    font-size: ${sizes.xxs};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.xxs};
        margin: 4px 0px;
    }
`

const Input = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.primary};
    margin: 4px 0px;

    /* Mobile screens */
    font-size: ${sizes.small};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.small};
        margin: 4px 0px;
    }
`

interface TextProps {
    textAlign: 'left' | 'right' | 'center'
    title: React.ReactNode
    text: React.ReactNode
}

const InfoText = ({ title, text, textAlign }: TextProps) => {
    return (
        <div>
            <Title style={{ textAlign }}>{title}</Title>
            <Input style={{ textAlign }}>{text}</Input>
        </div>
    )
}

const CustomToastWithLink = ({ txHash }: { txHash: string }) => (
    <div>
        Transaction sent! View the transaction on{' '}
        <a
            href={`https://etherscan.io/tx/${txHash}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: colors.accent }}
        >
            etherscan
        </a>
    </div>
)

interface ButtonBarProps {
    cube: Cube
}

type Selection = 'none' | 'pop' | 'mint' | 'bid' | 'offer'

declare const window: any

const ButtonBar = ({ cube }: ButtonBarProps) => {
    const { account, approval } = useSelector(selectMetamask)
    const dispatch = useDispatch<AppDispatch>()
    const [selected, setSelected] = useState<Selection>('none')
    const [loading, setLoading] = useState<boolean>(false)
    const [currency, setCurrency] = useState<'eth' | 'xsgd'>('xsgd')

    const handleGeneral = async (fn: (signer: Signer) => Promise<any>) => {
        setLoading(true)
        const provider = new ethers.providers.Web3Provider(
            window.ethereum,
            'any',
        )
        if (account.length === 0) {
            throw Error('Not connected')
        }
        const signer = provider.getSigner()
        let txHash = ''
        const tx = await toast
            .promise(
                fn(signer).then((tx) => {
                    setSelected('none')
                    txHash = tx.hash
                    return tx
                }),
                {
                    pending: 'Awaiting wallet signature',
                    success: {
                        render({ data }: any) {
                            return <CustomToastWithLink txHash={data.hash} />
                        },
                    },
                    error: {
                        render({ data }: any) {
                            return `Transaction failed: ${data.message}`
                        },
                    },
                },
            )
            .catch(() => setLoading(false))
        toast.promise(
            tx
                .wait()
                .then((res: any) => console.log(res))
                .finally(() => setLoading(false)),
            {
                pending: `Awaiting confirmation: ${txHash}`,
                success: 'Transaction succeeded',
                error: 'Transaction failed',
            },
        )
    }

    const handlePop = () => {
        handleGeneral((signer) => PopCube(signer, cube.data.id))
    }

    const handleApproval = () => {
        handleGeneral((signer) => increaseAllowance(signer, 10000)).then(() => {
            detectEthereumProvider().then((ethereum: unknown) => {
                const provider = new ethers.providers.Web3Provider(
                    ethereum as ExternalProvider,
                )
                dispatch(updateApproval({ provider, account: account[0] }))
            })
        })
    }

    const handleMint = () => {
        if (currency === 'eth') {
            handleGeneral((signer) => MintWithEth(signer, cube.data.id))
        } else if (currency === 'xsgd') {
            handleGeneral((signer) => MintWithXsgd(signer, cube.data.id))
        }
    }

    const handleClick = (selected: Selection) => {
        setSelected(selected)
    }

    const handleClickWrapper = (selection: Selection) => {
        handleClick(selected === selection ? 'none' : selection)
    }

    const loggedInButtons = (
        <>
            <ButtonRow>
                {!isUnminted(cube.data) && isOwner(account[0], cube) ? (
                    <TransactionButton
                        value="pop"
                        selected={selected === 'pop'}
                        onChange={() => handleClickWrapper('pop')}
                    >
                        Pop
                    </TransactionButton>
                ) : (
                    <div></div>
                )}
                {isUnminted(cube.data) && (
                    <TransactionButton
                        value="mint"
                        selected={selected === 'mint'}
                        onClick={() => handleClickWrapper('mint')}
                    >
                        Mint
                    </TransactionButton>
                )}
                {account && isOwner(account[0], cube) && (
                    <Tooltip arrow placement="right" title="Coming in Phase 2">
                        <span>
                            <TransactionButton
                                disabled
                                value="offer"
                                selected={selected === 'offer'}
                                onClick={() => handleClickWrapper('offer')}
                            >
                                Set Price
                            </TransactionButton>
                        </span>
                    </Tooltip>
                )}
                {!isUnminted(cube.data) && !isOwner(account[0], cube) && (
                    <Tooltip arrow placement="right" title="Coming in Phase 2">
                        <span>
                            <TransactionButton
                                disabled
                                value="bid"
                                selected={selected === 'bid'}
                                onClick={() => handleClickWrapper('bid')}
                            >
                                Make Bid
                            </TransactionButton>
                        </span>
                    </Tooltip>
                )}
            </ButtonRow>
            {selected === 'pop' && (
                <ButtonRow>
                    <div style={{ width: '47%' }}>
                        <Input style={{ textAlign: 'left' }}>
                            <>
                                Free 🎆 <br />{' '}
                                <span style={{ fontSize: 12 }}>
                                    (excluding gas fees)
                                </span>
                            </>
                        </Input>
                    </div>
                    <LoadButton
                        disabled={!isOwner(account[0], cube)}
                        onClick={handlePop}
                        loading={loading}
                    >
                        Send Txn
                    </LoadButton>
                </ButtonRow>
            )}
            {selected === 'mint' && (
                <ButtonRow style={{ alignItems: 'center' }}>
                    <div>
                        <Input style={{ textAlign: 'left' }}>
                            {currency === 'eth' ? '0.5 ETH' : '1000 XSGD'}{' '}
                            <br />{' '}
                            <span style={{ fontSize: 12 }}>
                                (excluding gas fees)
                            </span>
                        </Input>
                    </div>
                    <ToggleButtonGroup
                        size="small"
                        orientation="vertical"
                        exclusive
                        onChange={(e, v) => v !== null && setCurrency(v)}
                    >
                        <CurrencyButton
                            value="eth"
                            selected={currency === 'eth'}
                        >
                            <EthLogo size={28} />
                        </CurrencyButton>
                        <CurrencyButton
                            value="xsgd"
                            selected={currency === 'xsgd'}
                        >
                            <XsgdLogo size={24} />
                        </CurrencyButton>
                    </ToggleButtonGroup>
                    <Grid
                        container
                        width="132px"
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-end"
                        gap={1}
                    >
                        <LoadButton
                            onClick={
                                currency === 'eth' ||
                                (approval &&
                                    ethers.BigNumber.from(approval) >
                                        ethers.BigNumber.from(1000).mul(
                                            10 ** 6,
                                        ))
                                    ? handleMint
                                    : handleApproval
                            }
                            loading={loading}
                        >
                            {currency === 'eth' ||
                            (approval &&
                                ethers.BigNumber.from(approval) >
                                    ethers.BigNumber.from(1000).mul(10 ** 6))
                                ? 'Send Txn'
                                : 'Approve'}
                        </LoadButton>
                        {currency === 'xsgd' && approval && (
                            <span
                                style={{
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: colors.secondary,
                                }}
                            >
                                {`Approved for ${ethers.BigNumber.from(approval)
                                    .div(1000000)
                                    .toString()} XSGD`}
                            </span>
                        )}
                    </Grid>
                </ButtonRow>
            )}
        </>
    )

    return (
        <>
            {account.length ? (
                <>{loggedInButtons}</>
            ) : (
                <ButtonRow>
                    <Input style={{ color: `${colors.accentRed}` }}>
                        Connect wallet for more actions
                    </Input>
                </ButtonRow>
            )}
        </>
    )
}

interface Props {
    cube: Cube
    numSimilar: number
}

const Component = ({ cube, numSimilar }: Props) => {
    const { lastBlock } = useSelector(selectCube)
    const { account } = useSelector(selectMetamask)
    let owner = isUnminted(cube.data)
        ? 'Unminted'
        : truncateAccount(cube.data.owner.toLowerCase())
    owner =
        account.length > 0 && isOwner(account[0], cube)
            ? owner + ' (you)'
            : owner
    const coords = coordOfId(cube.data.id, 10)

    const getExpiryText = (cube: Cube) => {
        if (!isPopped(cube.data)) {
            return '-'
        }
        if (cube.data.popExpiry < lastBlock - 9998) {
            return `Many blocks ago`
        }
        if (cube.data.popExpiry < lastBlock) {
            return `${lastBlock - cube.data.popExpiry} blocks ago`
        } else {
            return `${cube.data.popExpiry - lastBlock + 1} blocks left`
        }
    }

    return (
        <Card>
            <ExporeLink
                href={`${CONFIG.block_explorer}/token/${CONFIG.contract_address}?a=${cube.data.id}`}
                target="_blank"
                rel="noreferrer"
            >
                View on Explorer
            </ExporeLink>
            <Header>
                <span style={{ color: `${colors.accentGold}` }}>ID: </span>
                {cube.data.id.toString().padStart(4, '0')}
            </Header>
            <Body>
                <BodyRow>
                    <InfoText textAlign="left" title="Owned by" text={owner} />
                    <InfoText
                        textAlign="right"
                        title="Coordinates"
                        text={`${coords.x} ${coords.y} ${coords.z}`}
                    />
                </BodyRow>
                <Rectangle> Price History not yet available</Rectangle>
                <BodyRow>
                    <Tooltip
                        arrow
                        placement="left"
                        title="Base colors are randomized at Mint and are permanent"
                    >
                        <span>
                            <InfoText
                                textAlign="left"
                                title="Base Color"
                                text={
                                    isUnminted(cube.data) ? (
                                        '-'
                                    ) : (
                                        <span
                                            style={{
                                                color: `${cube.data.baseColor}`,
                                            }}
                                        >
                                            {cube.data.baseColor}
                                        </span>
                                    )
                                }
                            />
                        </span>
                    </Tooltip>
                    <Tooltip
                        arrow
                        placement="bottom"
                        title="Pops temporarily change your Cube's color."
                    >
                        <span>
                            <InfoText
                                textAlign="center"
                                title="Pop Color"
                                text={
                                    isPopped(cube.data) ? (
                                        <span
                                            style={{
                                                color: `${cube.data.popColor}`,
                                            }}
                                        >
                                            {cube.data.popColor}
                                        </span>
                                    ) : (
                                        '-'
                                    )
                                }
                            />
                        </span>
                    </Tooltip>
                    <Tooltip
                        arrow
                        placement="right"
                        title={`The final block that a pop is active`}
                    >
                        <span>
                            <InfoText
                                textAlign="right"
                                title="Expiry"
                                text={getExpiryText(cube)}
                            />
                        </span>
                    </Tooltip>
                </BodyRow>
                <BodyRow>
                    <Tooltip arrow placement="left" title="Coming in Phase 2">
                        <span>
                            <InfoText
                                textAlign="left"
                                title="Pop Price (for non-owners)"
                                text={'-'}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Coming in Phase 2">
                        <span>
                            <InfoText
                                textAlign="right"
                                title="Owner's Buyout Price"
                                text={'-'}
                            />
                        </span>
                    </Tooltip>
                </BodyRow>
                <BodyRow>
                    <Tooltip
                        arrow
                        placement="left"
                        title={`Number of cubes with the same base color`}
                    >
                        <span>
                            <InfoText
                                textAlign="left"
                                title="Rarity"
                                text={numSimilar ? `1 of ${numSimilar}` : '-'}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Coming in Phase 2">
                        <span>
                            <InfoText
                                textAlign="right"
                                title="Highest Bid"
                                text={'-'}
                            />
                        </span>
                    </Tooltip>
                </BodyRow>
            </Body>
            <ButtonBar cube={cube} />
        </Card>
    )
}

export default Component

import { configureStore } from '@reduxjs/toolkit'
import {
    createStateSyncMiddleware,
    initMessageListener,
} from 'redux-state-sync'
import {
    persistCombineReducers,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import CubeReducer from './cubeSlice'
import MetamaskReducer from './metamaskSlice'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistCombineReducers(persistConfig, {
    cube: CubeReducer,
    metamask: MetamaskReducer,
})

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(
            createStateSyncMiddleware({
                blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
            }),
        ),
})

initMessageListener(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store

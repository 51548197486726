import { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { CubeOutline } from './logos/CubeOutline'
import { colors } from '../assets/styles/styleguide'

const Body = styled.div`
    z-index: 1000;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    background-color: black;
    transition: opacity 2s ease-in;
`

const lightIn = keyframes`
    0% {
      fill: #001219;
    }
    50% {
        fill: ${colors.accentGold};
    }
    100% {
        fill: #001219;
    }
`

const AnimatedCube = styled(CubeOutline)`
    animation: 2s ${lightIn} ease-in infinite alternate;
`

const Component = ({ loading }: { loading: boolean }) => {
    const [loadingPlus, setLoadingPlus] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoadingPlus(loading), 2000)
    }, [loading])
    return (
        <Body
            style={{
                opacity: loading ? 1 : 0,
                zIndex: loadingPlus ? 1000 : -1,
            }}
        >
            <AnimatedCube
                size={80}
                style={{
                    opacity: loading ? 1 : 0,
                }}
            />
        </Body>
    )
}

export default Component

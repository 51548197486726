import React, { useContext, useEffect, useState } from 'react'
import CubeInfoCard from '../components/cards/CubeInfoCard'
import MainCanvas from '../canvases/Main'
import styled from 'styled-components'
import SearchBox from '../components/searchbox/SearchBox'
import Branding from '../components/cards/Branding'
import ButtonBar from '../components/buttons/ButtonBar'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../redux/store'
import { useCubeFetch } from '../components/hooks/ethSubscriber'
import { SelectedCubeContext } from '../globalContextProvider'
import Loading from '../components/loading'
import { cubesOfCubeState, selectCube } from '../redux/cubeSlice'
import { sameBaseColor } from '../contract_interface/filters'
import { isUnminted } from '../contract_interface/cube'

const Body = styled.div`
    position: static;
    min-width: 360px;
    height: 100%;
    width: 100%;

    background: linear-gradient(246.48deg, #19163f 9.24%, #11021a 76.68%);

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    margin: 0px;
`

const Cv = () => {
    const { selected } = useContext(SelectedCubeContext)!
    const [loading, setLoading] = useState<boolean>(true)
    const dispatch = useDispatch<AppDispatch>()
    const cubes = cubesOfCubeState(useSelector(selectCube))
    const numSimilar = cubes.filter(sameBaseColor(selected)).length
    const numMinted = cubes.filter((cube) => !isUnminted(cube.data)).length
    const fetch = useCubeFetch(dispatch)

    useEffect(() => {
        setTimeout(() => setLoading(false), 5000)
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Loading loading={loading} />
            <Body>
                {selected && (
                    <CubeInfoCard cube={selected} numSimilar={numSimilar} />
                )}
                {!loading && <MainCanvas />}
                <SearchBox />
                <Branding numMinted={numMinted} />
                <ButtonBar mainPage />
            </Body>
        </>
    )
}

export default Cv

import { useEffect, useState } from 'react'
import * as THREE from 'three'
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/three'
import { isExpired, isPopped, isUnminted } from '../../contract_interface/cube'
import { Cube } from '../../contract_interface/types'

interface BoxProps {
    position: THREE.Vector3
    cube: Cube
    lastBlock: number
}

const Box = ({ cube, lastBlock }: BoxProps) => {
    const color =
        isPopped(cube.data) && !isExpired(cube.data, lastBlock)
            ? cube.data.popColor
            : !isUnminted(cube.data)
            ? cube.data.baseColor
            : undefined

    const popConfig = { mass: 1, tension: 100, friction: 20 }

    const [position, setPosition] = useState(0)
    const [rotation, setRotation] = useState(0)

    const style = useSpring({
        position: position,
        rotation: rotation,
        config: popConfig,
    })

    useEffect(() => {
        if (isPopped(cube.data) && !isExpired(cube.data, lastBlock)) {
            setPosition(0.8)
            setRotation(rotation + Math.PI * 1.5)
        }
        const timeoutId = window.setTimeout(() => {
            if (isPopped(cube.data) && !isExpired(cube.data, lastBlock)) {
                setPosition(0)
                setRotation(rotation + Math.PI * 1.5)
            }
        }, 300)
        return () => {
            window.clearTimeout(timeoutId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color, lastBlock])

    return (
        <a.mesh position-y={style.position} rotation-x={style.rotation}>
            <boxGeometry args={[0.5, 0.5, 0.5]} />
            <meshStandardMaterial
                roughness={1}
                color={color}
                opacity={
                    isPopped(cube.data) && !isExpired(cube.data, lastBlock)
                        ? 1
                        : 0.1
                }
                transparent={
                    !(isPopped(cube.data) && !isExpired(cube.data, lastBlock))
                }
            />
        </a.mesh>
    )
}

export default Box

/* eslint-disable */
import { ethers } from 'ethers'
import { BaseProvider } from '@ethersproject/providers'
import logoPng from './assets/images/logo.png'

////////////////////////////////////////////////////////////
//              ZILLIQA VERSION SETTINGS                  //
////////////////////////////////////////////////////////////

// These are set by the core protocol, and may vary per-chain.
// You can manually pack the bytes according to chain id and msg version.
// For more information: https://apidocs.zilliqa.com/?shell#getnetworkid

////////////////////////////////////////////////////////////
//                 PRODUCTION SETTINGS                    //
////////////////////////////////////////////////////////////

type ModeStr = 'PROD' | 'DEV' | 'ISOLATED' | 'LOCAL'

type Mode = {
    mode: ModeStr
    endpoint: string
    contract_address: string
    xsgd_proxy_address: string
    symbol: string // A ticker symbol or shorthand, up to 5 chars.
    decimals: number // The number of decimals in the token
    image: string
    contract_block_number: number
    block_explorer?: string
}

const PROD: Mode = {
    mode: 'PROD',
    endpoint: 'mainnet',
    contract_address: '0xe523627a06d2137141ceebe29db32ee47920069a',
    xsgd_proxy_address: '0x70e8de73ce538da2beed35d14187f6959a8eca96',
    symbol: '❑',
    decimals: 0,
    image: 'https://www.projectcube.io/static/media/logo.698eadd4.png',
    contract_block_number: 14936675,
    block_explorer: 'https://etherscan.io/',
}
// Rinkeby
const DEV: Mode = {
    mode: 'DEV',
    endpoint: 'rinkeby',
    contract_address: '0xdb471fC58Cde282d3003ec39011CDb0A34956446',
    xsgd_proxy_address: '0x8EC99887dD994f81D0488769930603fbd5BF59De',
    symbol: '❑',
    decimals: 0,
    image: 'https://www.projectcube.io/static/media/logo.698eadd4.png',
    contract_block_number: 10821045,
    block_explorer: 'https://rinkeby.etherscan.io/',
}
const LOCAL: Mode = {
    mode: 'LOCAL',
    endpoint: 'HTTP://127.0.0.1:7545',
    contract_address: '0x4FA962763C3eaeE5BBEc93C80b27BD9b5625d3BE',
    xsgd_proxy_address: '0x2c43b3a091E772fcc0bC478611d4C5f814284e10',
    symbol: '❑',
    decimals: 0,
    image: 'https://www.projectcube.io/static/media/logo.698eadd4.png',
    contract_block_number: 0,
}

// This is production mode. Change only this when changing to prod.
export const CONFIG = DEV

////////////////////////////////////////////////////////////
//                   LOGGER SETTINGS                      //
////////////////////////////////////////////////////////////

// Change settings here to apply globally

const Logger =
    CONFIG.mode === 'PROD'
        ? {
              log: () => {},
              info: () => {},
              debug: () => {},
              error: () => {},
          }
        : {
              log: console.log,
              info: console.info,
              debug: console.debug,
              error: console.error,
          }

Logger.info('Logger initalised')

export const LOGGER = Logger

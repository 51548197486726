import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import OwnedCubesButton from './OwnedCubesButton'
import LoggedInButton from './LoggedInButton'
import ConnectButton from './ConnectButton'
import DisconnectButton from './DisconnectButton'
import DownloadMetamaskButton from './DownloadMetamaskButton'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectMetamask,
    connectWallet,
    disconnectWallet,
    updateApproval,
} from '../../redux/metamaskSlice'
import { AppDispatch } from '../../redux/store'
import MetaMaskOnboarding from '@metamask/onboarding'
import { connectMetamask, getProvider } from '../hooks/metamask'
import { checkXsgdApproval } from '../../contract_interface/xsgd'
import Burger from './Burger'
import { mediaQuery } from '../../assets/styles/styleguide'
import { useOnClickOutside } from '../hooks/useOnClickOutside'

const StyledBox = styled.div<{ open: boolean }>`
    z-index: 98;
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    gap: 22px;
    background: rgba(17, 17, 17, 0.5);
    outline: 20px solid rgba(17, 17, 17, 0.5);
    border: 1px solid rgba(17, 17, 17, 0.5);
    text-align: right;
    padding: 80px 20px 12px 60px;
    border-radius: 8px;
    top: 0;
    right: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-110%)')};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        background: none;
        outline: none;
        border: none;
        gap: 12px;
        top: 24px;
        right: 24px;
        padding: 0;
        transform: translateX(0);
    }
`

const BurgerBar = styled.div`
    display: flex;
    justify-content: flex-end;
    top: 24px;
    right: 24px;
    z-index: 99;
    width: 100%;
    position: absolute;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        display: none;
    }
`

const MobileBurger = styled(Burger)`
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        display: none;
    }
`

const Component = ({ mainPage }: { mainPage: boolean }) => {
    const { status, account } = useSelector(selectMetamask)
    const dispatch = useDispatch<AppDispatch>()

    const [open, setOpen] = useState<boolean>(false)
    const node = useRef<HTMLDivElement>(null)
    useOnClickOutside(node, () => setOpen(false))

    const handleConnect = async () => {
        const account = (await connectMetamask()) as string[]
        if (account) {
            dispatch(connectWallet(account))
            const provider = getProvider()
            const approval = await checkXsgdApproval(provider, account[0])
            dispatch(updateApproval(approval))
        }
    }

    const handleDisconnect = () => {
        dispatch(disconnectWallet())
    }

    return (
        <div ref={node}>
            <BurgerBar>
                <MobileBurger open={open} setOpen={setOpen} />
            </BurgerBar>
            <StyledBox open={open}>
                {account.length !== 0 && (
                    <>
                        {mainPage && <OwnedCubesButton />}
                        <LoggedInButton
                            onClick={() => undefined}
                            account={account}
                        />
                        <DisconnectButton onClick={handleDisconnect} />
                    </>
                )}
                {account.length === 0 &&
                    MetaMaskOnboarding.isMetaMaskInstalled() && (
                        <ConnectButton
                            loading={status === 'loading'}
                            onClick={handleConnect}
                        />
                    )}
                {account.length === 0 &&
                    !MetaMaskOnboarding.isMetaMaskInstalled() && (
                        <DownloadMetamaskButton
                            onClick={new MetaMaskOnboarding().startOnboarding}
                        />
                    )}
            </StyledBox>
        </div>
    )
}

export default Component

import React, { createContext, useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'
import Landing from './pages/Landing'
import CubePage from './pages/CubePage'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
import { fonts } from './assets/styles/styleguide'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from './redux/store'
import { selectMetamask } from './redux/metamaskSlice'
import { createTheme, ThemeProvider } from '@mui/material'
import {
    useAccountsChanged,
    useChainChanged,
} from './components/hooks/metamask'
import { useSubscribe } from './components/hooks/ethSubscriber'
import { Cube } from './contract_interface/types'

const theme = createTheme({
    typography: {
        fontFamily: [
            'Chakra Petch',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
    },
})

const StyledContainer = styled(ToastContainer)`
    z-index: 100;
    .Toastify__toast {
        font-family: ${fonts.fontFamily};
    }
    .Toastify__progress-bar {
        height: 1px;
    }
`
export const SelectedCubeContext = createContext<{
    selected: Cube | undefined
    setSelected: React.Dispatch<React.SetStateAction<Cube | undefined>>
} | null>({ selected: undefined, setSelected: () => null })

const ContextProvider = () => {
    const dispatch = useDispatch<AppDispatch>()

    const [selected, setSelected] = useState<Cube | undefined>(undefined)
    const { account } = useSelector(selectMetamask)
    const cubeWatcher = useSubscribe(dispatch, null)
    const accountWatcher = useAccountsChanged(dispatch)
    const chainWatcher = useChainChanged(dispatch)

    useEffect(() => {
        cubeWatcher.subscribe()
        accountWatcher.subscribe()
        chainWatcher.subscribe()
        return () => {
            cubeWatcher.unsubscribe()
            accountWatcher.unsubscribe()
            chainWatcher.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    return (
        <>
            <ThemeProvider theme={theme}>
                <SelectedCubeContext.Provider value={{ selected, setSelected }}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={`/:id`} element={<CubePage />} />
                            <Route path={'/'} element={<Landing />} />
                            <Route
                                path="*"
                                element={<Navigate to="/" replace />}
                            />
                        </Routes>
                    </BrowserRouter>
                    <StyledContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover
                        theme="dark"
                        transition={Slide}
                    />
                </SelectedCubeContext.Provider>
            </ThemeProvider>
        </>
    )
}

export default ContextProvider

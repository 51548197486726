import { isUnminted } from './cube'
import { Cube } from './types'

export const isOwner = (owner: string) => {
    return function (cube: Cube, _index: number, _array: Cube[]) {
        if (!owner) {
            return false
        }
        return isUnminted(cube.data)
            ? false
            : cube.data.owner.toLowerCase() === owner.toLowerCase()
    }
}

export const sameBaseColor = (cube: Cube | undefined) => {
    return function (c: Cube, _index: number, _array: Cube[]) {
        return (
            !isUnminted(c.data) &&
            cube &&
            !isUnminted(cube.data) &&
            c.data.baseColor === cube.data.baseColor
        )
    }
}

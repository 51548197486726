import { EffectComposer, Bloom } from '@react-three/postprocessing'

const Post = () => {
    return (
        <EffectComposer multisampling={8}>
            <Bloom
                intensity={1}
                kernelSize={2}
                luminanceThreshold={0}
                luminanceSmoothing={0.4}
            />
        </EffectComposer>
    )
}

export default Post

import React from 'react'
import { createRoot } from 'react-dom/client'
import ContextProvider from './globalContextProvider'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import store from './redux/store'

const persistor = persistStore(store)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <PersistGate persistor={persistor}>
        <Provider store={store}>
            <ContextProvider />
        </Provider>
    </PersistGate>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

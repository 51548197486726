import { BigNumber, ethers, Signer } from 'ethers'
import { Provider } from '@ethersproject/providers'
import Abi from './FiatTokenV1.sol/FiatTokenV1.json'
import { CONFIG, LOGGER } from '../consts'

export const checkXsgdApproval = async (
    provider: Provider,
    account: string,
) => {
    LOGGER.log(`checkXsgdApproval called ${account}`)
    const contract = new ethers.Contract(
        CONFIG.xsgd_proxy_address,
        Abi.abi,
        provider,
    )
    const transactionResponse: BigNumber = await contract.allowance(
        account,
        CONFIG.contract_address,
    )
    return transactionResponse.toString()
}

export const increaseAllowance = async (signer: Signer, amount: number) => {
    LOGGER.log(`increaseAllowance called with ${amount}`)
    const contract = new ethers.Contract(
        CONFIG.xsgd_proxy_address,
        Abi.abi,
        signer,
    )
    const transactionResponse: BigNumber = await contract.increaseAllowance(
        CONFIG.contract_address,
        BigNumber.from(amount * 1000000),
    )
    return transactionResponse
}

import * as THREE from 'three'
import { useRef } from 'react'
import { useFrame } from '@react-three/fiber'

interface Props {
    children?: React.ReactNode
}

const Bounce = ({ children }: Props) => {
    const ref = useRef<THREE.Group>(null)
    useFrame((state, delta) => {
        if (ref && ref.current && ref.current.position) {
            ref.current.position.y += Math.cos(state.clock.elapsedTime) / 500
        }
    })

    return <group ref={ref}>{children}</group>
}

export default Bounce

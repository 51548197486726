const Lights = () => {
    return (
        <group>
            <ambientLight intensity={1.75} position={[2, 2, 0]} color="white" />
            <spotLight
                intensity={1}
                position={[-5, 10, 2]}
                angle={0.2}
                penumbra={1}
                castShadow
                shadow-mapSize={[2048, 2048]}
            />
            <rectAreaLight
                intensity={1}
                position={[-5, -5, 5]}
                width={10}
                height={10}
                onUpdate={(self) => self.lookAt(0, 0, 0)}
            />
            <rectAreaLight
                intensity={1}
                position={[5, 5, 5]}
                width={15}
                height={15}
                onUpdate={(self) => self.lookAt(0, 0, 0)}
            />
        </group>
    )
}

export default Lights

import styled from 'styled-components'
import { colors, sizes, fonts } from '../../assets/styles/styleguide'
import { CONFIG } from '../../consts'
import { truncateAccount } from '../../util/helpers'

const StyledBox = styled.button`
    display: flex;
    align-items: center;
    width: 132px;
    height: 32px;
    gap: 8px;
    padding: 0px 12px;

    border: 1px solid ${colors.accentGold};
    box-sizing: border-box;
    border-radius: 12px;
    background: ${colors.background};

    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: ${sizes.small};
    color: ${colors.accentGold};
    text-align: center;
    line-height: 150%;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    .logo {
        fill: ${colors.accentGold};
    }

    @media (hover: hover) {
       :hover {
        background: ${colors.accentGold};
        color: ${colors.backgroundDarkGrey};
        border: 0;

        .logo {
            fill: ${colors.backgroundDarkGrey};
        }
    }
`

const A = styled.a`
    text-decoration: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`

type ComponentProps = {
    account: string[]
    onClick: () => void
}

const Component = ({ account, onClick }: ComponentProps) => {
    return (
        <A
            href={`${CONFIG.block_explorer}address/${account[0]}`}
            target="_blank"
            rel="noreferrer"
        >
            <StyledBox onClick={onClick}>
                {truncateAccount(account[0])}
            </StyledBox>
        </A>
    )
}

export default Component

import { ethers } from 'ethers'
import { CONFIG } from '../../consts'
import { ExternalProvider } from '@ethersproject/providers'
import { AppDispatch } from '../../redux/store'
import { changeAccount, updateApproval } from '../../redux/metamaskSlice'
import { toast } from 'react-toastify'
import { checkXsgdApproval } from '../../contract_interface/xsgd'
import { wipeCubes } from '../../redux/cubeSlice'

declare const window: any

export const connectMetamask = () => {
    const padded = async () => {
        const res = window.ethereum?.request({
            method: 'eth_requestAccounts',
        })
        await new Promise((resolve) => setTimeout(resolve, 200))
        return res
    }
    return toast.promise(padded(), {
        pending: 'Connecting to wallet',
        error: {
            render({ data }) {
                return `Oops! There's likely a metamask connection in progress. Deal with that and refresh the page.`
            },
        },
        success: 'Successfully connected wallet',
    })
}

export const addCubeToken = async () =>
    window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
                address: CONFIG.contract_address, // The address that the token is at.
                symbol: CONFIG.symbol, // A ticker symbol or shorthand, up to 5 chars.
                decimals: CONFIG.decimals, // The number of decimals in the token
                image: CONFIG.image, // A string url of the token logo
            },
        },
    })

export const getProvider = () => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        return new ethers.providers.Web3Provider(
            window.ethereum as ExternalProvider,
        )
    }
    return ethers.providers.getDefaultProvider(CONFIG.endpoint)
}

const handleAccountsChanged =
    (dispatch: AppDispatch) => async (newAccounts: string[]) => {
        toast.info('Wallet account changed', {})
        const provider = getProvider()
        dispatch(changeAccount(newAccounts))
        const amount = await checkXsgdApproval(provider, newAccounts[0])
        dispatch(updateApproval(amount))
    }

export const useAccountsChanged = (dispatch: AppDispatch) => {
    const callback = handleAccountsChanged(dispatch)
    return {
        subscribe: () => window.ethereum?.on('accountsChanged', callback),
        unsubscribe: () =>
            window.ethereum?.removeListener('accountsChanged', callback),
    }
}

export const useChainChanged = (dispatch: AppDispatch) => {
    const callback = (chainId: any) => {
        dispatch(wipeCubes())
        window.location.reload()
    }
    return {
        subscribe: () => window.ethereum?.on('chainChanged', callback),
        unsubscribe: () =>
            window.ethereum?.removeListener('chainChanged', callback),
    }
}

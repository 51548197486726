import { ToggleButton, ToggleButtonProps } from '@mui/material'

const Component = (props: ToggleButtonProps) => {
    return (
        <ToggleButton
            sx={{
                backgroundColor: 'rgba(0,0,0,0)',
                textTransform: 'none',
                border: 'none',
                opacity: '0.2',
                padding: '4px',
                '&.Mui-selected': {
                    opacity: '1',
                    backgroundColor: 'rgba(0,0,0,0)',
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0)',
                    },
                },
                '&:hover': {
                    opacity: '1',
                    backgroundColor: 'rgba(0,0,0,0)',
                },
            }}
            disableRipple
            disableFocusRipple
            {...props}
        />
    )
}

export default Component

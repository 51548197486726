import * as React from 'react'
import styled from 'styled-components'
import { coordOfId, stringOfCoord, truncateAccount } from '../../util/helpers'
import {
    colors,
    sizes,
    fonts,
    mediaQuery,
} from '../../assets/styles/styleguide'
import { Link } from 'react-router-dom'
import { isUnminted } from '../../contract_interface/cube'
import { Cube } from '../../contract_interface/types'

const StyledCard = styled.div`
    position: absolute;
    left: 8px;
    top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 410px;
        height: 537px;
        left: 80px;
        top: 34px;
        gap: 14px;
    }
`

const Text = styled.div`
    /* Barlow Heading 4 */
    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: ${sizes.small};
    line-height: ${sizes.small};
    text-align: right;
    color: ${colors.primary};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.base};
    }
`

const IdText = styled(Text)`
    font-size: 100px;
    line-height: 80px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: 160px;
        line-height: 120px;
    }
`
const CoordText = styled(Text)`
    font-size: 72px;
    line-height: 70px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: 100px;
        line-height: 100px;
    }
`
const OwnerText = styled(Text)``
const PriceText = styled(Text)``
const BidsText = styled(Text)``
const MoreInfoLink = styled(Link)`
    color: ${colors.accent};
    z-index: 99;
    text-decoration: none;
    font-size: 14px;

    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: 20px;
    }
`

const Bar = styled.div<{ vertical?: boolean }>`
    top: ${(props) => (props.vertical ? '0px' : '50%')};
    left: ${(props) => (props.vertical ? '50%' : '0px')};
    width: ${(props) => (props.vertical ? '2px' : '150px')};
    height: ${(props) => (props.vertical ? '150px' : '2px')};
    background: ${colors.primary};
`

type ComponentProps = {
    cube: Cube
    numSimilar: number
}

const Component = ({ cube, numSimilar }: ComponentProps) => {
    const coords = coordOfId(cube.data.id, 10)
    return (
        <StyledCard>
            <IdText>{`ID${cube.data.id}`}</IdText>
            <CoordText>{stringOfCoord(coords)}</CoordText>
            <Bar />
            <OwnerText>
                {isUnminted(cube.data) ? (
                    'Unminted'
                ) : (
                    <>
                        Owned by{' '}
                        <span style={{ color: `${colors.accentGold}` }}>
                            {truncateAccount(cube.data.owner)}
                        </span>
                    </>
                )}
            </OwnerText>
            <PriceText>
                {isUnminted(cube.data) ? (
                    `1000 XSGD or 0.5 ETH`
                ) : (
                    <>
                        No buyout set{' '}
                        <span
                            style={{
                                color: colors.secondary,
                                fontSize: '12px',
                                lineHeight: sizes.small,
                            }}
                        >
                            Coming Soon
                        </span>
                    </>
                )}
            </PriceText>
            <BidsText>
                {isUnminted(cube.data)
                    ? `No base color yet`
                    : `1 of ${numSimilar}`}
            </BidsText>
            <MoreInfoLink
                to={`/${cube.data.id}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                View more
            </MoreInfoLink>
        </StyledCard>
    )
}

export default Component

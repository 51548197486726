import styled from 'styled-components'
import LogoUrl from '../../assets/images/logo.png'

const Logo = styled.div`
    height: 48px;
    width: 48px;
    background: url(${LogoUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100%;
`

const Component = () => {
    return <Logo />
}

export default Component

import * as THREE from 'three'
import { useRef } from 'react'
import { useFrame } from '@react-three/fiber'

interface Props {
    children?: React.ReactNode
}

const Explode = ({ children }: Props) => {
    const ref = useRef<THREE.Group>(null)
    useFrame((state, delta) => {
        if (ref && ref.current) {
            const delay = 2.4
            const animationTime = 0.6
            if (state.clock.elapsedTime - delay > animationTime + 10) {
                return
            }
            const timeScale =
                Math.min(
                    Math.max(0, state.clock.elapsedTime - delay),
                    animationTime,
                ) / animationTime
            ref.current.scale.x = timeScale ** 8
            ref.current.scale.y = timeScale ** 8
            ref.current.scale.z = timeScale ** 8
        }
    })

    return <group ref={ref}>{children}</group>
}

export default Explode

import styled from 'styled-components'
import { colors, sizes, fonts } from '../../assets/styles/styleguide'
import { CircularProgress } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

const StyledBox = styled.button<{ $loading: boolean; disabled: boolean }>`
    display: flex;
    align-items: center;
    min-width: 125px;
    height: 40px;
    gap: 6px;
    padding: 0px 12px;

    border: 1px solid
        ${(props) =>
            props.$loading || props.disabled
                ? colors.secondary
                : colors.accent};
    box-sizing: border-box;
    border-radius: 12px;
    background: ${colors.background};

    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: ${sizes.regular};
    color: ${(props) =>
        props.$loading || props.disabled ? colors.secondary : colors.accent};
    text-align: center;
    line-height: 150%;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    .logo {
        stroke: ${colors.accent};
    }

    :hover {
        background: ${(props) =>
            props.$loading || props.disabled
                ? colors.background
                : colors.accent};
        color: ${(props) =>
            props.$loading || props.disabled
                ? colors.secondary
                : colors.backgroundDarkGrey};
        border: '1px solid ${(props) =>
            props.$loading || props.disabled
                ? colors.secondary
                : colors.background}';

        .logo {
            stroke: ${colors.backgroundDarkGrey};
        }
    }
`

type ComponentProps = {
    children?: React.ReactChild | React.ReactChild[]
    onClick: React.MouseEventHandler<HTMLButtonElement>
    loading: boolean
    disabled?: boolean
}

const Component = ({
    children,
    onClick,
    loading,
    disabled,
}: ComponentProps) => {
    const logoSize = 14
    return (
        <StyledBox
            onClick={onClick}
            $loading={loading}
            disabled={disabled || false}
        >
            {children}
            {loading ? (
                <CircularProgress size={logoSize} />
            ) : (
                <SendIcon fontSize="small" />
            )}
        </StyledBox>
    )
}

export default Component

export const colors = {
    background: '#000000',
    backgroundOverlay: '#111111',
    backgroundDarkGrey: '#393939',
    backgroundGlass: 'rgba(17, 17, 17, 0.1)',
    primary: '#F1F1F1',
    secondary: '#B4B4B4',
    accent: '#7ED5EA',
    accentRed: '#FF1010',
    accentGreen: '#70EF7D',
    accentGold: '#DDC690',
    boxShadow: `rgba(255, 255, 255, 0.2)`,
    highlight: '#0F2557',
    warning: '#FFB800',
    popColors: {
        red: '#fc2847',
        orange: '#ffa343',
        yellow: '#fdfc74',
        green: '#71bc78',
        blue: '#0088ff',
        indigo: '#8349e6',
        pink: '#fb7efd',
    },
}

export const sizes = {
    xxs: '12px',
    xs: '14px',
    small: '16px',
    regular: '18px',
    large: '20px',
    base: '24px',
    xl: '30px',
    xxl: '36px',
    xxxl: '40px',
    title: '64px',
}

export const fonts = {
    fontFamilyHeader: 'Poppins',
    fontFamily: 'Chakra Petch',
}

export const mediaQuery = {
    desktopMin: '1200px',
    laptopMax: '1200px',
    laptopMin: '992px',
    tablexMax: '992px',
    tabletMin: '768px',
    mobileMax: '768px',
    mobileMin: '768px',
    minDesktop: '1200px',
}

export const isMobile =
    window.screen.width <
    parseInt(mediaQuery.laptopMin.substring(0, mediaQuery.laptopMin.length - 1))

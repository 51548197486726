import * as React from 'react'
import styled from 'styled-components'
import { colors, fonts, mediaQuery } from '../../assets/styles/styleguide'
import Logo from '../elements/Logo'

const StyledCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    left: 12px;
    bottom: 92px;

    position: absolute;
    /* Barlow Heading 4 */
    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: right;
    color: ${colors.primary};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        left: 80px;
        bottom: 32px;
        font-size: 14px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
`

const GreyText = styled.a`
    color: ${colors.secondary};
    text-decoration: none;
    z-index: 99;
`

type ComponentProps = {
    numMinted: number
}

const Component = ({ numMinted }: ComponentProps) => {
    const d = new Date()

    return (
        <StyledCard>
            <Logo />
            <Row>
                <GreyText href={`https://projectcube.io`}>
                    projectcube.io
                </GreyText>
                <span>Cube World</span>
                <GreyText href={`https://projectcube.io`}>
                    @projectcubeio
                </GreyText>
            </Row>

            <Row>
                <span>Limited Release</span>
                <span>{`${numMinted} of 1000 Minted`}</span>
            </Row>
            <Row>
                <span>{`${d.toLocaleString('default', {
                    month: 'short',
                })} ${d.getDate()}`}</span>
                <span>{`${d.getFullYear()}`}</span>
            </Row>
        </StyledCard>
    )
}

export default Component

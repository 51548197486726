import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Cube as CubeIcon } from '../logos/Cube'
import { useSelector } from 'react-redux'
import { selectMetamask } from '../../redux/metamaskSlice'
import { cubesOfCubeState, selectCube } from '../../redux/cubeSlice'
import { isOwner } from '../../contract_interface/filters'
import { Cube } from '../../contract_interface/types'
import { colors, sizes, fonts } from '../../assets/styles/styleguide'
import { IconButton, IconButtonProps } from '@mui/material'
import { LocationSearching, OpenInNew } from '@mui/icons-material'
import { SelectedCubeContext } from '../../globalContextProvider'

const Button = (props: IconButtonProps) => {
    return (
        <IconButton
            sx={{
                height: 32,
                gap: '8px',
                padding: `0px 12px 0px 10px`,
                fontFamily: fonts.fontFamily,
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: sizes.small,
                color: colors.accentGold,
                backgroundColor: colors.background,
                borderRadius: 3,
                textTransform: 'none',
                border: `1px solid ${colors.accentGold}`,
                '&.Mui-selected': {
                    border: `1px solid ${colors.accentGold}`,
                    backgroundColor: colors.accentGold,
                    '&:hover': {
                        border: `1px solid ${colors.accentGold}`,
                        backgroundColor: colors.accentGold,
                    },
                },
                '&.Mui-disabled': {
                    color: colors.secondary,
                    border: `1px solid ${colors.secondary}`,
                },
                '&:hover': {
                    color: colors.background,
                    border: `1px solid ${colors.backgroundDarkGrey}`,
                    backgroundColor: colors.accentGold,
                },
            }}
            disableRipple
            disableFocusRipple
            {...props}
        />
    )
}

const ITEM_HEIGHT = 48

const Component = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const { account } = useSelector(selectMetamask)
    const cubes = cubesOfCubeState(useSelector(selectCube))
    const ownedCubes = account ? cubes.filter(isOwner(account[0])) : []
    const selectedCubeContext = React.useContext(SelectedCubeContext)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        return ownedCubes.length ? setAnchorEl(event.currentTarget) : null
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Button
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <>
                    <CubeIcon size={14} />{' '}
                    <span>{ownedCubes.length.toString()}</span>
                </>
            </Button>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                        backgroundColor: colors.background,
                    },
                }}
            >
                {ownedCubes.map((cube: Cube) => (
                    <MenuItem
                        key={cube.data.id}
                        selected={false}
                        disableRipple={true}
                        disableTouchRipple={true}
                        sx={{
                            justifyContent: 'space-between',
                            color: colors.primary,
                            cursor: 'default',
                            '&:hover': {
                                cursor: 'default',
                            },
                        }}
                    >
                        <span>
                            <CubeIcon size={12} />
                            {` ${cube.data.id}`}
                        </span>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={() =>
                                    selectedCubeContext?.setSelected(cube)
                                }
                            >
                                <LocationSearching
                                    sx={{
                                        color: colors.primary,
                                        '&:hover': {
                                            color: colors.accent,
                                        },
                                    }}
                                    fontSize="small"
                                />
                            </IconButton>
                            <a
                                href={`/${cube.data.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <OpenInNew
                                    sx={{
                                        color: colors.primary,
                                        '&:hover': {
                                            color: colors.accent,
                                        },
                                    }}
                                    fontSize="small"
                                />
                            </a>
                        </span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default Component

import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import { cubeDatumToCube } from '../contract_interface/cube'
import {
    Cube,
    Mint,
    Pop,
    Transfer,
    CubeDatum,
} from '../contract_interface/types'
import _ from 'lodash'

type CubeState = {
    lastUpdate: number
    lastBlock: number
    cubeData: Array<CubeDatum>
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initDummyCubes = new Array(1000).fill(0).map((_value, index) => {
    return { cubeId: index + 1 }
})

const initialState: CubeState = {
    lastUpdate: new Date(0).valueOf(),
    lastBlock: 0,
    cubeData: initDummyCubes,
    status: 'idle',
}

const _updateCubes = (
    cubeData: Array<CubeDatum>,
    events: {
        mintEvents: Mint[]
        transferEvents: Transfer[]
        popEvents: Pop[]
    },
): Array<CubeDatum> => {
    if (cubeData.length !== 1000) {
        throw Error('CubeState incorrect, length not 1000')
    }
    const newCubeData = _.cloneDeep(cubeData)
    for (const ev of events.mintEvents) {
        const idx = ev.cubeId - 1
        newCubeData[idx].lastMint = ev
    }

    for (const ev of events.popEvents) {
        const idx = ev.cubeId - 1
        const prev = newCubeData[idx]
        if (
            !prev.lastPop ||
            (prev.lastPop &&
                (prev.lastPop.blockNumber < ev.blockNumber ||
                    (prev.lastPop.blockNumber === ev.blockNumber &&
                        prev.lastPop.logIndex < ev.logIndex)))
        ) {
            newCubeData[idx].lastPop = ev
        }
    }

    for (const ev of events.transferEvents) {
        const idx = ev.cubeId - 1
        const prev = newCubeData[idx]
        if (
            !prev.lastTransfer ||
            (prev.lastTransfer &&
                (prev.lastTransfer.blockNumber < ev.blockNumber ||
                    (prev.lastTransfer.blockNumber === ev.blockNumber &&
                        prev.lastTransfer.logIndex < ev.logIndex)))
        ) {
            newCubeData[idx].lastTransfer = ev
        }
    }

    return newCubeData
}

export const cubeSlice = createSlice({
    name: 'cube',
    initialState,
    reducers: {
        wipeCubes: (state) => {
            return {
                lastUpdate: new Date().valueOf(),
                lastBlock: state.lastBlock,
                cubeData: initDummyCubes,
                status: 'succeeded',
            }
        },
        updateCubes: (state, action) => {
            return {
                lastUpdate: new Date().valueOf(),
                lastBlock: state.lastBlock,
                cubeData: _updateCubes(state.cubeData, action.payload),
                status: 'succeeded',
            }
        },
        updateBlock: (state, action) => {
            return {
                lastUpdate: new Date().valueOf(),
                lastBlock: action.payload,
                cubeData: state.cubeData,
                status: 'succeeded',
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { wipeCubes, updateCubes, updateBlock } = cubeSlice.actions

export const selectCube = (state: RootState) => state.cube

export const cubesOfCubeState = (cubeState: CubeState): Cube[] => {
    return cubeState.cubeData.map((cubeDatum) => cubeDatumToCube(cubeDatum))
}

export default cubeSlice.reducer

import { colors } from '../assets/styles/styleguide'
import { Cube, CubeDatum, MintedCube, PoppedCube, UnmintedCube } from './types'
import { ethers, Signer } from 'ethers'
import Abi from './ProjectCube.sol/ProjectCube.json'
import { CONFIG, LOGGER } from '../consts'

export const isPopped = (
    cube: UnmintedCube | MintedCube | PoppedCube,
): cube is PoppedCube => {
    return (cube as PoppedCube).popColor !== undefined
}

export const isExpired = (cube: PoppedCube, currentBlock: number): boolean => {
    return isPopped(cube) && cube.popExpiry < currentBlock
}

export const isMintedNotPopped = (
    cube: UnmintedCube | MintedCube | PoppedCube,
): cube is MintedCube => {
    return !isPopped(cube) && (cube as MintedCube).owner !== undefined
}

export const isUnminted = (
    cube: UnmintedCube | MintedCube | PoppedCube,
): cube is UnmintedCube => {
    return !isPopped(cube) && !isMintedNotPopped(cube)
}

export const isOwner = (address: string, cube: Cube): boolean => {
    if (!address) {
        return false
    }
    return (
        !isUnminted(cube.data) &&
        cube.data.owner.toLowerCase() === address.toLowerCase()
    )
}

export const UnmintedCubeWithId = (id: number): Cube => {
    return {
        data: {
            id,
            mintPrice: 1000_000000,
        },
    }
}

export const MintedCubeWithId = (id: number): Cube => {
    const roll = Math.random()
    let colorId = 0
    if (roll > 0.78) {
        colorId = 0
    } else if (roll > 0.56) {
        colorId = 1
    } else if (roll > 0.34) {
        colorId = 2
    } else if (roll > 0.12) {
        colorId = 3
    } else if (roll > 0.06) {
        colorId = 4
    } else if (roll > 0.02) {
        colorId = 5
    } else {
        colorId = 6
    }
    return {
        data: {
            id,
            owner: ethers.Wallet.createRandom().address,
            baseColor: Object.values(colors.popColors)[colorId],
        },
    }
}

export const PoppedCubeWithId = (id: number): Cube => {
    return {
        data: {
            ...MintedCubeWithId(id).data,
            popColor: Object.values(colors.popColors)[
                Math.floor((Math.random() * 100) % 7)
            ],
            popExpiry: 0,
        },
    }
}

export const PopCube = async (signer: Signer, cubeId: number) => {
    LOGGER.log('PopCube Called')
    const contract = new ethers.Contract(
        CONFIG.contract_address,
        Abi.abi,
        signer,
    )
    const transactionResponse = await contract.popToken(cubeId)
    LOGGER.log(`Transaction Hash: ${transactionResponse.hash}`)
    return transactionResponse
}

export const MintWithXsgd = async (signer: Signer, cubeId: number) => {
    LOGGER.log('MintWithXsgd Called')
    const contract = new ethers.Contract(
        CONFIG.contract_address,
        Abi.abi,
        signer,
    )
    const transactionResponse = await contract.mintWithXsgd(cubeId)
    LOGGER.log(`Transaction Hash: ${transactionResponse.hash}`)
    return transactionResponse
}

export const MintWithEth = async (signer: Signer, cubeId: number) => {
    LOGGER.log('MintWithEth Called')
    const contract = new ethers.Contract(
        CONFIG.contract_address,
        Abi.abi,
        signer,
    )
    const transactionResponse = await contract.mintWithEth(cubeId, {
        value: ethers.utils.parseEther('0.5'),
    })
    LOGGER.log(`Transaction Hash: ${transactionResponse.hash}`)
    return transactionResponse
}

export const cubeDatumToCube = (datum: CubeDatum): Cube => {
    if (!datum.lastMint) {
        return UnmintedCubeWithId(datum.cubeId)
    } else if (!datum.lastPop) {
        return {
            data: {
                id: datum.cubeId,
                baseColor: datum.lastMint.baseColor,
                owner: datum.lastTransfer
                    ? datum.lastTransfer.to
                    : datum.lastMint.to,
            },
        }
    } else {
        return {
            data: {
                id: datum.cubeId,
                baseColor: datum.lastMint.baseColor,
                owner: datum.lastTransfer
                    ? datum.lastTransfer.to
                    : datum.lastMint.to,
                popColor: datum.lastPop.popColor,
                popExpiry: datum.lastPop.expiry,
            },
        }
    }
}

import { colors, sizes, fonts } from '../../assets/styles/styleguide'
import { ToggleButton, ToggleButtonProps } from '@mui/material'

const Component = (props: ToggleButtonProps) => {
    return (
        <ToggleButton
            sx={{
                fontFamily: fonts.fontFamily,
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: sizes.large,
                color: colors.accentGold,
                backgroundColor: colors.background,
                minWidth: 125,
                height: 40,
                borderRadius: 3,
                textTransform: 'none',
                border: `1px solid ${colors.accentGold}`,
                '&.Mui-selected': {
                    border: `1px solid ${colors.accentGold}`,
                    backgroundColor: colors.accentGold,
                    '&:hover': {
                        border: `1px solid ${colors.accentGold}`,
                        backgroundColor: colors.accentGold,
                    },
                },
                '&.Mui-disabled': {
                    color: colors.secondary,
                    border: `1px solid ${colors.secondary}`,
                },
                '&:hover': {
                    color: colors.background,
                    border: `1px solid ${colors.backgroundDarkGrey}`,
                    backgroundColor: colors.accentGold,
                },
            }}
            disableRipple
            disableFocusRipple
            {...props}
        />
    )
}

export default Component

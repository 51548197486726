import * as THREE from 'three'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { isExpired, isPopped, isUnminted } from '../../contract_interface/cube'
import { Cube } from '../../contract_interface/types'
import { coordOfId } from '../../util/helpers'

interface BoxesProps {
    cubeList: Array<Cube>
    selected: Cube | undefined
    setSelected: React.Dispatch<React.SetStateAction<Cube | undefined>>
    popped: boolean
    lastBlock: number
}

const Boxes = ({
    cubeList,
    selected,
    setSelected,
    popped,
    lastBlock,
}: BoxesProps) => {
    const [hovered, setHovered] = useState<number | undefined>()
    const ref = useRef<THREE.InstancedMesh<THREE.BufferGeometry>>(null)
    const cubesPerSide = 10

    useLayoutEffect(() => {
        if (ref.current) {
            const offset = (cubesPerSide + 1) / 2
            const imesh = ref.current
            cubeList.forEach((cube, index) => {
                let color = new THREE.Color('white')
                if (cube.data.id === 0) {
                    if (selected) {
                        const { x, y, z } = coordOfId(
                            selected.data.id,
                            cubesPerSide,
                        )
                        const mat = new THREE.Matrix4()
                        mat.setPosition(x - offset, y - offset, z - offset)
                        imesh.setMatrixAt(index, mat)
                    } else {
                        const mat = new THREE.Matrix4()
                        mat.setPosition(1000, 1000, 1000)
                        imesh.setMatrixAt(index, mat)
                    }
                } else {
                    const { x, y, z } = coordOfId(cube.data.id, cubesPerSide)
                    const mat = new THREE.Matrix4()
                    mat.setPosition(x - offset, y - offset, z - offset)
                    imesh.setMatrixAt(index, mat)
                    if (selected?.data.id === cube.data.id) {
                        color = new THREE.Color('aquamarine')
                    } else if (hovered === index) {
                        color = new THREE.Color('aquamarine')
                    } else if (popped) {
                        color = new THREE.Color(
                            isPopped(cube.data) &&
                            !isExpired(cube.data, lastBlock)
                                ? cube.data.popColor
                                : '#999999',
                        )
                    } else if (!popped) {
                        color = new THREE.Color(
                            !isUnminted(cube.data)
                                ? cube.data.baseColor
                                : '#999999',
                        )
                    }
                }
                imesh.setColorAt(index, color)
            })
            imesh.instanceMatrix && (imesh.instanceMatrix.needsUpdate = true)
            imesh.instanceColor && (imesh.instanceColor.needsUpdate = true)
        }
    }, [cubeList, hovered, popped, selected, lastBlock])

    useFrame(() => {
        const imesh = ref.current
        const offset = (cubesPerSide + 1) / 2
        if (imesh) {
            cubeList.forEach((cube, index) => {
                let color = new THREE.Color('white')
                if (cube.data.id === 0) {
                    if (selected) {
                        const { x, y, z } = coordOfId(
                            selected.data.id,
                            cubesPerSide,
                        )
                        const mat = new THREE.Matrix4()
                        mat.setPosition(x - offset, y - offset, z - offset)
                        imesh.setMatrixAt(index, mat)
                    } else {
                        const mat = new THREE.Matrix4()
                        mat.setPosition(1000, 1000, 1000)
                        imesh.setMatrixAt(index, mat)
                    }
                } else {
                    if (selected?.data.id === cube.data.id) {
                        color = new THREE.Color('aquamarine')
                    } else if (hovered === index) {
                        color = new THREE.Color('aquamarine')
                    } else if (popped) {
                        color = new THREE.Color(
                            isPopped(cube.data) &&
                            !isExpired(cube.data, lastBlock)
                                ? cube.data.popColor
                                : '#999999',
                        )
                    } else if (!popped) {
                        color = new THREE.Color(
                            !isUnminted(cube.data)
                                ? cube.data.baseColor
                                : '#999999',
                        )
                    }
                }
                imesh.setColorAt(index, color)
            })
            imesh.instanceColor && (imesh.instanceColor.needsUpdate = true)
            imesh.instanceMatrix && (imesh.instanceMatrix.needsUpdate = true)
        }
    })

    return (
        <instancedMesh
            onClick={(e) => {
                e.stopPropagation()
                if (e.instanceId === undefined) {
                    setSelected(undefined)
                } else {
                    setSelected(
                        selected?.data.id === cubeList[e.instanceId].data.id
                            ? undefined
                            : cubeList[e.instanceId],
                    )
                }
            }}
            onPointerMove={(e) => {
                e.stopPropagation()
                setHovered(e.instanceId)
            }}
            onPointerOut={(e) => setHovered(undefined)}
            ref={ref}
            args={[undefined, undefined, cubeList.length]}
        >
            <boxBufferGeometry args={[0.4, 0.4, 0.4]} />
            <meshStandardMaterial
                roughness={1}
                color={popped ? 'white' : '#999999'}
                opacity={popped ? 1 : 0.1}
                transparent={!popped}
                depthWrite={popped}
            />
        </instancedMesh>
    )
}

export default Boxes

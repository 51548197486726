import { CONFIG, LOGGER } from '../consts'
import { ethers, Event as EthersEvent } from 'ethers'
import { Listener, Provider } from '@ethersproject/abstract-provider'
import CubeAbi from './ProjectCube.sol/ProjectCube.json'
import { Mint, Transfer, Pop } from './types'

export const isMintEvent = (event: Mint | Transfer | Pop): event is Mint => {
    return (event as Mint).baseColor !== undefined
}

export const isTransferEvent = (
    event: Mint | Transfer | Pop,
): event is Transfer => {
    return (event as Transfer).from !== undefined
}

export const isPopEvent = (event: Mint | Transfer | Pop): event is Pop => {
    return (event as Pop).popColor !== undefined
}

export const getEthersEventsFrom = async (
    provider: Provider,
    startBlock: number,
): Promise<{
    mintEvents: EthersEvent[]
    transferEvents: EthersEvent[]
    popEvents: EthersEvent[]
}> => {
    const contract = new ethers.Contract(
        CONFIG.contract_address,
        CubeAbi.abi,
        provider,
    )
    const mintFilter = contract.filters.Mint()
    const mintEventsPromise = contract.queryFilter(
        mintFilter,
        startBlock,
        'latest',
    )
    const transferFilter = contract.filters.Transfer()
    const transferEventsPromise = contract.queryFilter(
        transferFilter,
        startBlock,
        'latest',
    )
    const popFilter = contract.filters.Popped()
    const popEventsPromise = contract.queryFilter(
        popFilter,
        startBlock,
        'latest',
    )
    const [mintEvents, transferEvents, popEvents] = await Promise.all([
        mintEventsPromise,
        transferEventsPromise,
        popEventsPromise,
    ])
    return { mintEvents, transferEvents, popEvents }
}

export const mintMap = (e: EthersEvent): Mint => {
    if (e.event !== 'Mint') {
        throw Error(`Expected Mint event, got ${e.event}`)
    }
    if (!e.args) {
        LOGGER.info('WARNING: Event has no args')
    }
    return {
        blockNumber: e.blockNumber,
        logIndex: e.logIndex,
        cubeId: e.args?.tokenId.toNumber(),
        baseColor: e.args?.baseColor,
        to: e.args?.to,
    }
}

export const transferMap = (e: EthersEvent): Transfer => {
    if (e.event !== 'Transfer') {
        throw Error(`Expected Transfer event, got ${e.event}`)
    }
    if (!e.args) {
        LOGGER.info('WARNING: Event has no args')
    }
    return {
        blockNumber: e.blockNumber,
        logIndex: e.logIndex,
        cubeId: e.args?.tokenId.toNumber(),
        from: e.args?.from,
        to: e.args?.to,
    }
}

export const popMap = (e: EthersEvent): Pop => {
    if (e.event !== 'Popped') {
        throw Error(`Expected Transfer event, got ${e.event}`)
    }
    if (!e.args) {
        LOGGER.info('WARNING: Event has no args')
    }
    return {
        blockNumber: e.blockNumber,
        logIndex: e.logIndex,
        cubeId: e.args?.tokenId.toNumber(),
        popColor: (e.args as any)[1].color,
        expiry: (e.args as any)[1].expiry.toNumber(),
    }
}

export const ethersEventsToCubeEvents = ({
    mintEvents,
    transferEvents,
    popEvents,
}: {
    mintEvents: EthersEvent[]
    transferEvents: EthersEvent[]
    popEvents: EthersEvent[]
}): {
    mintEvents: Mint[]
    transferEvents: Transfer[]
    popEvents: Pop[]
} => {
    const m = mintEvents.map(mintMap)
    const t = transferEvents.map(transferMap)
    const p = popEvents.map(popMap)
    return { mintEvents: m, transferEvents: t, popEvents: p }
}

export const getCubeData = async (provider: Provider, startBlock: number) => {
    LOGGER.log('Fetching cube data')
    return getEthersEventsFrom(provider, startBlock).then(
        ethersEventsToCubeEvents,
    )
}

export const eventSubscriber = (
    provider: Provider,
    cubeId: number | null,
    event: 'pop' | 'mint' | 'transfer',
    listener: Listener,
) => {
    const contract = new ethers.Contract(
        CONFIG.contract_address,
        CubeAbi.abi,
        provider,
    )
    const getFilter = (event: 'pop' | 'mint' | 'transfer') => {
        if (event === 'pop') {
            return contract.filters.Popped(cubeId)
        } else if (event === 'mint') {
            return contract.filters.Mint(cubeId, null, null)
        } else if (event === 'transfer') {
            return contract.filters.Transfer(null, null, cubeId)
        }
        throw Error('No event type specified')
    }
    const filter = getFilter(event)
    return {
        subscribe: () => contract.on(filter, listener),
        unsubscribe: () => contract.off(filter, listener),
    }
}

export const mintEventSubscriber = (
    provider: Provider,
    cubeId: number | null,
    listener: Listener,
) => eventSubscriber(provider, cubeId, 'mint', listener)
export const popEventSubscriber = (
    provider: Provider,
    cubeId: number | null,
    listener: Listener,
) => eventSubscriber(provider, cubeId, 'pop', listener)
export const transferEventSubscriber = (
    provider: Provider,
    cubeId: number | null,
    listener: Listener,
) => eventSubscriber(provider, cubeId, 'transfer', listener)

import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import { toast } from 'react-toastify'

type MetamaskState = {
    lastUpdate: number
    account: string[]
    approval: string | null
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initialState: MetamaskState = {
    lastUpdate: new Date(0).valueOf(),
    account: [],
    approval: null,
    status: 'idle',
}

export const metamaskSlice = createSlice({
    name: 'metamask',
    initialState,
    reducers: {
        updateApproval: (state, action) => {
            return {
                lastUpdate: new Date().valueOf(),
                account: state.account,
                approval: action.payload,
                status: 'succeeded',
            }
        },
        changeAccount: (state, action) => {
            return {
                lastUpdate: new Date().valueOf(),
                account: action.payload,
                approval: null,
                status: 'succeeded',
            }
        },
        disconnectWallet: (state) => {
            toast.info('Wallet disconnected', {})
            return {
                lastUpdate: new Date().valueOf(),
                account: [],
                approval: null,
                status: 'idle',
            }
        },
        connectWallet: (state, action) => {
            return {
                lastUpdate: new Date().valueOf(),
                account: action.payload,
                approval: null,
                status: 'succeeded',
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    connectWallet,
    disconnectWallet,
    changeAccount,
    updateApproval,
} = metamaskSlice.actions

export const selectMetamask = (state: RootState) => state.metamask

export default metamaskSlice.reducer

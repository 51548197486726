import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { colors } from '../../assets/styles/styleguide'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: colors.background,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.background,
    },
}))

export default StyledTooltip

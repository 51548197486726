import * as THREE from 'three'
import React, { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Cube } from '../contract_interface/types'
import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { LayerMaterial, Depth } from 'lamina'
import { isMobile } from '../assets/styles/styleguide'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import Box from '../components/renders/singleBox'
import { selectCube } from '../redux/cubeSlice'
import { useSelector } from 'react-redux'
interface Props {
    cube: Cube
}

const Lights = () => {
    return (
        <group>
            <ambientLight intensity={1.75} position={[2, 2, 0]} color="white" />
            <rectAreaLight
                intensity={2}
                position={[-5, -5, -5]}
                width={10}
                height={10}
                onUpdate={(self) => self.lookAt(0, 0, 0)}
            />
            <rectAreaLight
                intensity={2}
                position={[5, 5, 5]}
                width={15}
                height={15}
                onUpdate={(self) => self.lookAt(0, 0, 0)}
            />
        </group>
    )
}

type BgProps = {
    layerColor: THREE.ColorRepresentation
    color1: THREE.ColorRepresentation
    color2: THREE.ColorRepresentation
    color3: THREE.ColorRepresentation
}

function Bg({ layerColor, color1, color2, color3 }: BgProps) {
    const mesh = useRef<THREE.Mesh<THREE.BufferGeometry>>(null)
    useFrame((state, delta) => {
        if (mesh && mesh.current) {
            mesh.current.rotation.x =
                mesh.current.rotation.y =
                mesh.current.rotation.z +=
                    delta
        }
    })
    return (
        <mesh ref={mesh} scale={100}>
            <sphereGeometry args={[1, 64, 64]} />
            <LayerMaterial
                color={layerColor}
                attach="material"
                side={THREE.BackSide}
            >
                <Depth
                    colorA={color1}
                    colorB={color3}
                    alpha={0.5}
                    mode="multiply"
                    near={0}
                    far={300}
                    origin={[10, 10, 10]}
                />
                <Depth
                    colorA={color2}
                    colorB={color3}
                    alpha={0.5}
                    mode="multiply"
                    near={0}
                    far={300}
                    origin={[100, 100, 100]}
                />
            </LayerMaterial>
        </mesh>
    )
}

const MyCanvas = ({ cube }: Props) => {
    const { lastBlock } = useSelector(selectCube)
    return (
        <Canvas>
            <Box
                position={new THREE.Vector3(0, 0, 0)}
                cube={cube}
                lastBlock={lastBlock}
            />
            {!isMobile && (
                <Bg
                    layerColor={'#9a9a9a'}
                    color1={'blue'}
                    color2={'#ff0000'}
                    color3={'#00aaff'}
                />
            )}
            <Lights />
            <EffectComposer multisampling={8}>
                <Bloom
                    intensity={1.75}
                    kernelSize={2}
                    luminanceThreshold={0}
                    luminanceSmoothing={0.4}
                />
            </EffectComposer>
            <OrbitControls
                makeDefault
                enablePan={false}
                autoRotate={true}
                enableRotate={true}
                autoRotateSpeed={2.5}
                rotateSpeed={0.4}
                enableZoom={false}
                minDistance={isMobile ? 1.5 : 3}
                maxDistance={isMobile ? 1.5 : 3}
                target={[0, 0, 0]}
            />
            <PerspectiveCamera
                makeDefault
                fov={65}
                position={[0, 0.5, 2]}
                view={{
                    enabled: true,
                    fullWidth: window.screen.width,
                    fullHeight: window.screen.height,
                    offsetX: isMobile ? 0 : 240,
                    offsetY: 0,
                    width: window.screen.width,
                    height: window.screen.height,
                }}
            />
        </Canvas>
    )
}

export default MyCanvas
